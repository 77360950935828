import md5 from 'crypto-js/md5';

export default function getSecretStr(obj) {
  const secretKey = `^_*#06@!@6#_^`;
  const obj2 = JSON.parse(JSON.stringify(obj));
  for (const key in obj2) {
    if (['coLicensePic', 'cardPic1', 'cardPic2', 'cardPic3', 'reject_reason', 'sign'].includes(key)) {
      delete obj2[key];
    } else if (typeof obj2[key] !== "string" && typeof obj2[key] !== 'number' && typeof obj2[key] !== 'boolean') {
      delete obj2[key]
    } else if (typeof obj2[key] === 'string' && (obj2[key].length > 30 || obj2[key].includes(" ") || !obj2[key])) {
      delete obj2[key]
    }
  }
  const keys = Object.keys(obj2).sort((a, b) => (a < b ? -1 : 1));
  let secretStr = '';
  keys.forEach((item) => {
    secretStr += `${item}=>${obj2[item]}@`;
  });
  secretStr = secretStr.substring(0, secretStr.length - 1);
  console.log('------ 待签名字符 ------', secretStr)
  secretStr = md5(secretStr).toString();
  console.log('MD5字符串', secretStr)
  secretStr = secretStr + secretKey;
  console.log('MD5字符串拼接', secretStr)
  secretStr = md5(secretStr).toString().toUpperCase();
  console.log('------ 最终结果 ------', secretStr)
  return secretStr;
}
