<template>
  <template v-if="isWeChat">
    <div>
      <img style="width: 100vw; height: auto;" src="@/assets/wechat.jpg" />
    </div>
  </template>
  <template v-else>
    <div class="flex-col-center">
      <img src="@/assets/pc-bg2.png" class="img1" />
      <img src="@/assets/pc-bg1.png" class="img2" />
      <div class="tips">
        <div class="tips-t1">
          <img src="@/assets/tip2.png" alt="">
          <div>{{ $t(`请注意`) }}！</div>
        </div>
        <div class="tips-t2">
          {{ $t(`咕噜分发的所有应用都来源于第三方产品，内容不受咕噜分发控制，请您仔细甄别，审慎安装，咕噜分发仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保活承诺，咕噜分发不为因此产生的任何投资、购买行为承担任何责任`) }}。
        </div>
      </div>
      <div class="flex1" style="min-height: 20px;"></div>
      <div class="foot" style="flex-wrap: wrap;">
        <div><a href="https://www.gulufenfa.com/#/home/introduction">关于咕噜</a></div>
        <div><a href="https://www.gulufenfa.com/#/home/doc?type=2">免责声明</a></div>
        <div id="showModal">举报反馈</div>
        <div class="hide-border"><a href="https://www.gulufenfa.com/#/home/index">访问咕噜</a></div>
        <a
          style="margin: 14px auto;width: 100%"
          class="flex-center"
          href="https://beian.mps.gov.cn"
          target="_blank"
        >
          <div style="color: #777777;">沪ICP备18035940号-32</div>
        </a>
      </div>
    </div>
  </template>
  <div class="modal">
    <div class="modal-container modal-container_4">
      <div class="modal-content">"{{ $t(`未受信任的企业级开发者`) }}"{{ $t(`的解决办法`) }}</div>
      <div class="modal-tip">
        <form action="" method="post">
          <div class="tit">{{ $t(`举报反馈`) }}</div>
          <p>
            {{ $t(`作为第三方内测分发平台，我们一直致力于打击违规应用，保护用户权益。非常感谢您的举报，我们会在第一时间安排专人处理您的举报问题。感谢您对我们的支持`) }}。
          </p>
          <div class="form-group">
            <label>请选择举报类型<span>*</span></label>
            <ul class="clearfix">
              <li>
                <input type="radio" class="hidden" value="0">
                盗版
              </li>
              <li>
                <input type="radio" class="hidden" value="1">
                黄色
              </li>
              <li>
                <input type="radio" class="hidden" value="2">
                欺诈
              </li>
              <li>
                <input type="radio" class="hidden" value="3">
                其它
              </li>
            </ul>
            <div class="error">请选择举报类型</div>
          </div>

          <div class="form-group">
            <label>举报原因<span>*</span></label>
            <textarea class="form-control" rows="4" id="report-content" placeholder="补充举报原因"></textarea>
            <div class="error">请填写举报原因</div>
          </div>

          <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control" name="email" placeholder="Email" id="report-email">
          </div>

          <div class="form-group">
            <label>你的手机号码<span>*</span></label>
            <input type="text" class="form-control" name="mobile" placeholder="你的手机号码" id="report-mobile">
            <div class="error">请填写手机号</div>
          </div>


          <div class="form-group">
            <label>请输入短信验证码<span>*</span></label>
            <div class="flex" style="height: 40px;">
              <input type="text" class="form-control" name="code" placeholder="请输入短信验证码" id="report-code"
                style="width: 60%;">
              <button id="sendcode" style="flex-shrink: 0;" onclick="DAFU.send_sms_code()" type="button"
                class="ms-btn ms-btn-primary-new">获取验证码</button>
            </div>

            <div class="error">请输入短信验证码</div>
          </div>

          <div class="btn-bottom">
            <button data-dismiss="modal" type="button" class="ms-btn color-hover-new">返回下载页</button>
            <button type="button" class="ms-btn ms-btn-primary-new save">举报</button>
          </div>
        </form>
      </div>
      <div class="modal-btn">确定</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const isWeChat = ref(false)

let ua = navigator.userAgent.toLowerCase();
if (ua.match(/MicroMessenger/i) == "micromessenger") {
  isWeChat.value = true;
  // alert("用户正在使用微信内置浏览器打开网页！")
} else {
  // alert("用户没有使用微信打开网页！")
}
onMounted(() => {
  const showModalBtn = document.getElementById('showModal')
  showModalBtn.onclick = () => {
    showModal()
  }
})

function showModal() {
  const modal = document.querySelector('.modal')
  const modalBox = document.querySelector('.modal-container')
  const btn = document.querySelector('.modal-btn')

  modal.style.display = 'flex'
  modalBox.onclick = (e) => {
    e.stopPropagation()
  }
  modal.onclick = () => {
    modal.style.display = 'none'
  }
  btn.onclick = () => {
    modal.style.display = 'none'
  }
}
</script>

<style lang="less" scoped>
:root {
  font-size: calc(100vw / 7.5);
}


@media screen and (min-width: 471px) {
  :root {
    font-size: 63px;
  }
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
}

.img1 {
  width: 340px;
  height: auto;
  margin-top: 55px;
  margin-bottom: 1px;
}

.img2 {
  width: 265px;
  height: auto;
}

.tips {
  // width: 800px;
  width: 80vw;
  min-height: 104px;
  background: #FBFBFB;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  flex-shrink: 0;
}

.tips img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.tips .tips-t1 {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  align-items: center;
  padding-left: 16.5px;
  padding-top: 14px;
}

.tips .tips-t2 {
  margin-left: 41.5px;
  width: calc(100% - 41.5px);
  padding-bottom: 5px;
  font-size: 12px;
  color: #333;
  margin-top: 1px;
}

.foot {
  width: 100vw;
  font-size: 14px;
  font-weight: 400;
  color: #81D330;
  display: flex;
  justify-content: center;
  height: 132px;
  background: #F3F3F3;
  padding-top: 15px;
  padding-bottom: 20px;
}

.foot>div {
  border-right: 1px solid #81D330;
  padding-right: 10px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.foot a {
  color: #81D330;
  text-decoration: none;
}

.foot .hide-border {
  border: none;
}

.flex1 {
  flex: 1;
  width: 1px;
}

.hand {
  cursor: pointer;
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 5.8rem;
  min-height: 4.82rem;
  max-height: 80%;
  overflow: scroll;
  background: #FFFFFF;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  font-size: 0.28rem;
  color: #333;
  width: 4.84rem;
  text-align: center;
  margin-top: 0.68rem;
}

.modal-tip {
  width: 4.84rem;
  text-align: center;
  font-size: 0.24rem;
  color: #999;
  margin-top: 0.24rem;
}

.modal-btn {
  width: 3.05rem;
  height: 0.88rem;
  border: 0.02rem solid #81D330;
  border-radius: 0.44rem;
  color: #81D330;
  font-size: 0.32rem;
  display: flex;
  margin-top: 0.24rem;
  flex-shrink: 0;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

/* Add your custom CSS styles here */
/* For example: */
.form-group {
  margin-top: 5px;
}

.form-group label {
  font-weight: bold;
  text-align: left;
  width: 100%;
  display: block;
}

.form-group input[type="text"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: left;
}

.form-group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: url("select-arrow.png") no-repeat right center; */
  background-size: 16px;
  padding-right: 24px;
  text-align-last: left;
}

.form-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-group li {
  margin-bottom: 10px;
  text-align: left;
}

.form-group li span {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.form-group li span.selected {
  background-color: #00aaff;
  border-color: #00aaff;
}

.form-group .error {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

.btn-bottom {
  margin-top: 20px;
  text-align: left;
}

/* Add your custom CSS styles here */
/* For example: */
.ms-btn {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ms-btn:hover {
  background-color: #f5f5f5;
}

.ms-btn-primary-new {
  background-color: #00aaff;
  color: #fff;
  border-color: #00aaff;
}

.ms-btn-primary-new:hover {
  background-color: #0099dd;
  border-color: #0099dd;
}

.color-hover-new {
  color: #00aaff;
}

.flex {
  display: flex;
}
</style>
