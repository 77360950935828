<template>
	<div>
		<div v-if="!showLoading">
			<div class="header" v-if="showTips">
				<div>应用仅供内部测试，请谨慎辨别下载</div>
				<div class="bottom-btn flex-center">
					<div class="flex-center hand" id="showModal2-2" @click="showModal(5)">
						免责声明
					</div>
					<div class="flex-center hand" @click="showModal(4)">举报反馈</div>
				</div>
				<div class="close-icon hand" @click="showTips = false">x</div>
			</div>
			<div v-if="showError" style="padding: 20px" class="flex-center">
				{{ errorText }}
			</div>
			<div
				v-else-if="!isMobile"
				class="flex-center flex-col"
				style="padding: 20px 0"
			>
				<h2>请使用手机扫描二维码下载</h2>
				<img :src="appInfo.icon" class="icon" />
				<h3>{{ appInfo.name }}</h3>
				<img :src="appInfo.qrCode" class="code" />
			</div>
			<div v-else>
				<div class="app-info flex">
					<img :src="appInfo.icon" class="icon" />
					<div class="flex1 flex-col" style="height: 100%; padding: 4px 0">
						<h3 class="flex1">{{ appInfo.name }}</h3>
						<div class="flex">
							<div @click="toDownload" v-if="loadStatus == 0">安装配置</div>
							<div class="btn" @click="toDownloadApk" v-if="loadStatus == 1">
								下载安装
							</div>
							<a :href="configFileUrl" v-else-if="loadStatus == 2">
								<div class="btn" v-if="loadStatus == 2">
									信任开发
								</div>
							</a>
							<!-- <div class="btn" @click="toDownload" v-if="loadStatus == 2">
								信任开发
							</div> -->
						</div>
					</div>
				</div>
				<div
					class="app-rate"
					style="justify-content: space-between; padding: 8px 20px"
				>
					<div class="flex" style="width: 100%; justify-content: space-between">
						<div class="flex-center">
							<strong>4.9</strong>
							<img src="@/assets/5x.png" alt="" />
							<!-- <a-rate :value="5" /> -->
						</div>
						<div>
							<strong>18+</strong>
						</div>
					</div>
					<strong style="font-size: 10px; margin-top: -4px; color: #d8d8d8"
						>999+评价</strong
					>
				</div>
				<hr class="hr" />
				<h2 style="padding: 0 20px">评分及评论</h2>
				<div class="rate-box p-20px pt-0">
					<div class="flex-center" style="justify-content: space-between">
						<div class="left">
							<h1 style="margin-bottom: 0">4.9</h1>
							<div>满分5分</div>
						</div>
						<div class="right">
							<div
								style="
									width: 100%;
									height: 70px;
									display: flex;
									flex-direction: column;
									justify-content: space-around;
									align-content: space-around;
								"
							>
								<div class="line-box">
									<img src="@/assets/5x.png" alt="" />
									<div class="line"></div>
								</div>
								<div class="line-box">
									<img src="@/assets/5x.png" alt="" />
									<div class="line"></div>
								</div>
								<div class="line-box">
									<img src="@/assets/5x.png" alt="" />
									<div class="line"></div>
								</div>
								<div class="line-box">
									<img src="@/assets/5x.png" alt="" />
									<div class="line"></div>
								</div>
								<div class="line-box">
									<img src="@/assets/5x.png" alt="" />
									<div class="line"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr class="hr" />
				<div class="rate-box p-20px pt-0">
					<h2>简介</h2>
					<h4>{{ appInfo.discription }}</h4>
				</div>
				<hr class="hr" />
				<div class="p-20px pt-0">
					<h2>信息</h2>
					<div class="info-items">
						<div class="l">经销商</div>
						<div class="r">#</div>
					</div>
					<div class="info-items">
						<div class="l">版本</div>
						<div class="r">{{ appInfo.ver }}</div>
					</div>
					<div class="info-items">
						<div class="l">兼容性</div>
						<div class="r">#</div>
					</div>
					<div class="info-items">
						<div class="l">语言</div>
						<div class="r">#</div>
					</div>
					<div class="info-items">
						<div class="l">大小</div>
						<div class="r">{{ appInfo.size }}</div>
					</div>
					<div class="info-items">
						<div class="l">更新时间</div>
						<div class="r">{{ appInfo.utime }}</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			v-loading="true"
			style="width: 100vw; height: 100vh"
			class="flex-center"
		>
			<a-spin size="large" />
		</div>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";
import { apiDomain, request } from "@/request.js";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import getSecretStr from "@/utils/secretStr";

function isMobileDevice() {
	return window.matchMedia("only screen and (max-width: 960px)").matches;
}

const isMobile = ref(isMobileDevice());
const showTips = ref(true);
const showError = ref(false);
const errorText = ref("");
const appInfo = ref({});
const showLoading = ref(true);
const route = useRoute();
const router = useRouter();
const configFileUrl = ref("");

const appCode = computed(() => {
	return route.params.id;
});
const authCode = computed(() => {
	return route.query.c;
});
const decValue = computed(() => {
	return route.query.dec;
});
// if (!authCode.value) {
// 	router.replace(`/${appCode.value}`)
// }
const loadStatus = ref(0);
if (decValue.value === 'on') {
	loadStatus.value = 1;	
}

axios
	.post(
		apiDomain + "/api/download/main",
		{ code: appCode.value, c: authCode.value },
		{
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			},
		}
	)
	.then((res) => {
		if (res.status != 200 || res.data?.code != 0)
			throw { msg: res.data?.msg } || "请求失败";
		const data = res.data.data;
		appInfo.value = data;
	})
	.catch((err) => {
		// alreadyLoaded.value = true;
		showError.value = true;
		errorText.value = err;
		console.log("err:", err);
	})
	.finally(() => {
		showLoading.value = false;
	});
const toDownload = async () => {
	// const res = {
	// 	link: "https://web.gulutest.com/udid/37fe560a4957326251adf77fa854171e-sign.mobileconfig",
	// 	url: "https://web.gulutest.com/udid/app.mobileprovision",
	// };
	const res = await request(
		"/api/download/downMobileFile",
		{
			appid: appInfo.value.id,
			c: authCode.value,
			uuid: uuidv4(),
		},
		{
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			},
		}
	);
	// console.log(res);
	window.location.href = res.link;
	// setTimeout(async () => {
	// 	loadStatus.value = 1;
	// }, 3000);
};
const toDownloadApk = async () => {
	const params = {
		code: appCode.value,
		appid: appInfo.value.id,
		c: authCode.value,
		uuid: uuidv4(),
		nonceStr: uuidv4().substr(0, 16),
	};
	const res = await request(
		"/api/download/install",
		{
			...params,
			sign: getSecretStr(params),
		},
		{
			headers: {
				"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			},
		}
	);
	window.location.href =
		"itms-services://?action=download-manifest&url=" +
		encodeURIComponent(res.apk);
	downloadCertificate();
};

const downloadCertificate = () => {
	axios
		.post(
			apiDomain + "/api/download/trustDeveloper",
			{
				app_name: appInfo.value.name,
				team: appInfo.value.team,
				bid: appInfo.value.bundle_id,
				uid: appInfo.value.uid,
			},
			{
				headers: {
					"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
				},
			}
		)
		.then((res) => {
			if (res?.data?.code == 0) configFileUrl.value = res?.data?.data;
			setTimeout(() => {
				loadStatus.value = 2;
			}, 3000);
		});
};
</script>

<style lang="less" scoped>
.header {
	background: rgb(64, 212, 126);
	padding: 20px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	flex-direction: column;
	position: relative;
	.close-icon {
		position: absolute;
		bottom: 10px;
		right: 40px;
		font-size: 25px;
		color: red;
		// background: red;
		// border-radius: 50%;
	}
}

.app-info {
	padding: 0 20px;
	margin-top: 20px;
	height: 120px;
	.icon {
		margin: 0 20px 0 0;
	}
	.btn {
		padding: 4px 20px;
		font-size: 12px;
		color: white;
		background: #0477f9;
		border-radius: 200px;
	}
}

.info-items {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	padding: 4px 0;
	border-bottom: 1px solid #f2f2f2;
	.l {
		color: #737379;
	}
	.r {
		color: black;
	}
}

.rate-box {
	height: 70px;
	margin-bottom: 20px;
	.right {
		flex: 1;
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-content: space-around;
		height: 100%;
		.line-box {
			display: flex;
			align-items: center;
			img {
				height: 7px;
				width: 46px;
			}
			.line {
				flex: 1;
				margin-left: 8px;
				height: 2px;
				background: #ccc;
			}
		}
	}
}

.p-20px {
	padding: 20px;
}
.pt-0 {
	padding-top: 0;
}

.hr {
	margin: 10px 0;
	border: 1px solid #eee;
	color: #eee;
}

.app-rate {
	color: #888;
	font-size: 12px;
	img {
		height: 12px;
		width: auto;
		margin-left: 4px;
	}
}

.icon {
	width: 120px;
	height: 120px;
	margin: 20px 0;
	border-radius: 25px;
}
.code {
	width: 256px;
	height: 256px;
}
.bottom-btn {
	margin-top: 20px;
}
.bottom-btn div {
	color: white;
	font-size: 0.24rem;
	width: 1.36rem;
	height: 0.56rem;
	border-radius: 0.28rem;
}

.bottom-btn > div:nth-child(1) {
	background: #409cff;
}

.bottom-btn > div:nth-child(2) {
	background: #e87272;
	margin-left: 0.24rem;
}
</style>
