export default {
    "请点击右上角": "請點擊右上角",
    "打开菜单": "打開菜單",
    "选择用Safari打开下载": "選擇用Safari打開下載",
    "点击下方复制网址按钮，粘贴至Safari打开下载": "點擊下方複製網址按鈕，粘貼至Safari打開下載",
    "复制网址打开Safari浏览器打开下载": "複製網址打開Safari瀏覽器打開下載",
    "请注意": "請注意",
    "您即将访问的应用为用户自行上传，请甄别应用风险后进行下载": "您即將訪問的應用為用戶自行上傳，請甄別應用風險後進行下載",
    "继续访问": "繼續訪問",
    "请输入": "請輸入",
    "密码": "密碼",
    "授权码": "授權碼",
    "立即进入": "立即進入",
    "版本": "版本",
    "大小": "大小",
    "更新时间": "更新時間",
    "授权安装使用教程": "授權安裝使用教程",
    "未受信任的企业级开发者的解决办法": "未受信任的企業級開發者的解決辦法",
    "安装配置": "安裝配置",
    "下载安装": "下載安裝",
    "正在安装，请按home键返回桌面查看": "正在安裝，請按home鍵返回桌面查看",
    "信任开发": "信任開發",
    "该应用来源于第三方产品, 请甄别风险后进行下载": "該應用來源於第三方產品，請甄別風險後進行下載",
    "我已知晓内测应用的来源，并同意自行承担下载此APP所带来的一切风险": "我已知晓内测應用的來源，並同意自行承擔下載此APP所帶來的一切風險",
    "扫描二维码下载": "掃描二維碼下載",
    "应用描述": "應用描述",
    "应用介绍": "應用介紹",
    "联系方式": "聯繫方式",
    "备注": "備註",
    "免责声明": "免責聲明",
    "举报反馈": "舉報反饋",
    "APP备案号": "APP備案號",
    "应用仅供内部测试，请谨慎辨别下载": "應用僅供內部測試，請謹慎辨別下載",
    "返回": "返回",
    "该应用为“咕噜分发”实名用户自行上传至咕噜分发使用CDN加速下载，未绑定自主域名内测": "該應用為“咕嚕分發”實名用戶自行上傳至咕嚕分發使用CDN加速下載，未綁定自主域名內測",
    "如发现违规应用，请你在跳转后点击举报": "如發現違規應用，請你在跳轉後點擊舉報",
    "确定": "確定",
    "1.咕噜分发平台对该等程序仅可进行初步的形式审查，无法详细分析每一应用程序的运行后果及风险。请您审慎地甄别应用程序的质量，切勿因为垃圾短信、邮件、文字说明等内容随意安装来源不可靠的应用程序，请根据应用安装链接的来源自行判断并安装可信任的应用程序。对于通过咕噜分发平台发布的应用程序或咕噜分发平台上展示的其他站点的链接，咕噜分发平台不对该程序或站点的规则、政策或具体内容负责。": "1.咕噜分发平臺對該等程式僅可進行初步的形式審查，無法詳細分析每一應用程式的運行後果及風險。請您審慎地甄別應用程式的質量，切勿因為垃圾簡訊、郵件、文字說明等內容隨意安裝來源不可靠的應用程式，請根據應用安裝鏈接的來源自行判斷並安裝可信任的應用程式。對於通過咕嚕分發平臺發布的應用程式或咕嚕分發平臺上展示的其他站點的鏈接，咕嚕分發平臺不對該程序或站點的規則、政策或具體內容負責。",
    "2.咕噜分发内测服务平台为第三方应用提供应用测试托管、应用分布式CDN加速下载服务。应用内容均来源于第三方产品，咕噜分发平台仅为用户提供内测支持，不涉及任何人工编辑和整理，也不参与任何应用的内容开发和对外运营，不对任何来源于第三方的内容（包括但不限于安装包安全、信息描述、应用截图）承担责任，您可根据描述场景谨慎选择进行内测试用。": "2.咕噜分發內測服務平臺為第三方應用提供應用測試托管、應用分布式CDN加速下載服務。應用內容均來源於第三方產品，咕嚕分發平臺僅為用戶提供內測支持，不涉及任何人工編輯和整理，也不參與任何應用的內容開發和對外運營，不對任何來源於第三方的內容（包括但不限於安裝包安全、信息描述、應用截圖）承擔責任，您可根據描述場景謹慎選擇進行內測。",
    "3.在您通过咕噜分发平台安装或使用应用程序的过程中，咕噜分发平台不会要求您提供任何个人信息，但我们无法控制亦无法保证应用程序开发者不会要求您提供个人信息。无论依据国家法律、法规的规定，应用程序开发者收集、使用您的个人信息应当遵循合法、正当、必要的原则，公开收集规则，向您明示收集、使用信息的目的、方式和范围，并且必须经过您的同意。应用程序开发者不得收集与其提供服务无关的个人信息，不得违反法律、法规规定或与您之间的约定收集、使用您的个人信息。如您在通过咕噜分发平台安装或使用应用程序的过程中被要求提供个人信息，请您审慎考虑并自行决定是否提供。": "3.在您通過咕嚕分發平臺安裝或使用應用程式的過程中，咕嚕分發平臺不會要求您提供任何個人信息，但我們無法控制亦無法保證應用程式開發者不會要求您提供個人信息。無論依據國家法律、法規的規定，應用程式開發者收集、使用您的個人信息應當遵循合法、正當、必要的原則，公開收集規則，向您明示收集、使用信息的目的、方式和範圍，並且必須經過您的同意。應用程式開發者不得收集與其提供服務無關的個人信息，不得違反法律、法規規定或與您之間的約定收集、使用您的個人信息。如您在通過咕嚕分發平臺安裝或使用應用程式的過程中被要求提供個人信息，請您審慎考慮並自行決定是否提供。",
    "4.任何公司、产品或者个人认为咕噜分发平台涉嫌侵犯您的版权或应用权，您应该及时向我们提供举报声明、书面权利通知，并提供身份证明、权属证明及侵权情况等投诉材料。我们将依法进行处理。": "4.任何公司、產品或者個人認為咕嚕分發平臺涉嫌侵犯您的版權或應用權，您應該及時向我們提供舉報聲明、書面權利通知，並提供身份證明、權屬證明及侵權情況等投訴材料。我們將依法進行處理。",
    "5.咕噜分发平台的服务是按照现有技术和条件所能达到的现状提供的。咕噜分发平台会尽最大努力向您提供服务，确保服务的连贯性和安全性；但咕噜分发平台不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。即使咕噜分发平台提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为咕噜分发平台违约，同时，由此给您造成的数据或信息丢失等损失的，您同意放弃追究咕噜分发平台的责任。": "5.咕嚕分發平臺的服務是按照現有技術和條件所能達到的現狀提供的。咕嚕分發平臺會盡最大努力向您提供服務，確保服務的連貫性和安全性；但咕嚕分發平臺不能保證其所提供的服務毫無瑕疵，也無法隨時預見和防範法律、技術以及其他風險，包括但不限於不可抗力、病毒、木馬、黑客攻擊、系統不穩定、第三方服務瑕疵、政府行為等原因可能導致的服務中斷、數據丟失以及其他的損失和風險。即使咕嚕分發平臺提供的服務存在瑕疵，但上述瑕疵是當時行業技術水平所無法避免的，其將不被視為咕嚕分發平臺違約，同時，由此給您造成的數據或信息丟失等損失的，您同意放棄追究咕嚕分發平臺的責任。",
    "6.咕噜分发平台平台所接触的内容和信息来源广泛，我方无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。咕噜分发平台不对用户在我方平台上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。我方平台在法律有明确规定的情况下承担相应的责任。": "6.咕嚕分發平臺平臺所接觸的內容和信息來源廣泛，我方無法對該內容和信息的準確性、真實性、可用性、安全性、完整性和正當性負責。咕嚕分發平臺不對用戶在我方平臺上傳、發布或傳輸的任何內容和信息背書、推薦或表達觀點，也不對任何內容和信息的錯誤、瑕疵及產生的損失或損害承擔任何責任，您對內容和信息的任何使用需自行承擔相關的風險。我方平臺在法律有明確規定的情況下承擔相應的責任。",
    "作为第三方内测分发平台，我们一直致力于打击违规应用，保护用户权益。非常感谢您的举报，我们会在第一时间安排专人处理您的举报问题。感谢您对我们的支持。": "作為第三方內測分發平臺，我們一直致力於打擊違規應用，保護用戶權益。非常感謝您的舉報，我們會在第一時間安排專人處理您的舉報問題。感謝您對我們的支持。",
    "请选择举报类型": "請選擇舉報類型",
    "举报应用下载地址": "舉報應用下載地址",
    "请输入举报应用下载地址": "請輸入舉報應用下載地址",
    "举报理由": "舉報理由",
    "请选择举报理由": "請選擇舉報理由",
    "是否造成金钱损失": "是否造成金錢損失",
    "请选择是或否": "請選擇是或否",
    "是": "是",
    "否": "否",
    "请选择是否造成金钱损失": "請選擇是否造成金錢損失",
    "举报原因": "舉報原因",
    "请填写举报原因": "請填寫舉報原因",
    "你的手机号码": "你的手機號碼",
    "请填写手机号": "請填寫手機號",
    "请输入短信验证码": "請輸入短信驗證碼",
    "获取验证码": "獲取驗證碼",
    "返回下载页": "返回下載頁",
    "举报": "舉報",
    "我同意": "我同意",
    "向右滑动": "向右滑動",
    "应用不存在或已删除": "應用不存在或已刪除",
    "发送成功": "發送成功",
    "请求失败": "請求失敗",
    "请求错误": "請求錯誤",
    "提示": "提示",
    "取消": "取消",
    "请勾选免责声明": "請勾選免責聲明",
    "请填验证码": "請填驗證碼",
    "提交成功": "提交成功",
    "请使用手机扫描二维码下载": "請使用手機掃描二維碼下載",
    "咕噜分发的所有应用都来源于第三方产品，内容不受咕噜分发控制，请您仔细甄别，审慎安装，咕噜分发仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保活承诺，咕噜分发不为因此产生的任何投资、购买行为承担任何责任": "咕嚕分發的所有應用都來源於第三方產品，內容不受咕嚕分發控制，請您仔細甄別，審慎安裝，咕嚕分發僅為信息服務平臺，與第三方應用之間不存在任何關聯關係，不提供任何擔保活承諾，咕嚕分發不為因此產生的任何投資、購買行為承擔任何責任",
    "关于咕噜": "關於咕嚕",
    "访问咕噜": "訪問咕嚕",
    "未受信任的企业级开发者": "未受信任的企業級開發者",
    "盗版": "盜版",
    "黄色": "黃色",
    "欺诈": "欺詐",
    "其它": "其他",
    "补充举报原因": "補充舉報原因",
	"开发者信息": "開發者資訊",
	"开发者姓名": "開發者姓名",
	"开发者手机号": "開發者手機號",
	"开发者邮箱": "開發者郵箱",
	"请同意风险声明": "請同意風險聲明"
}
