<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

:root {
  font-size: calc(100vw / 7.5);
}

@media screen and (min-width: 471px) {
  :root {
    font-size: 63px;
  }
}


.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex1 {
  flex: 1;
}

.hand {
  cursor: pointer;
}
</style>
