export default {
    "请点击右上角": "Please click the top right corner",
    "打开菜单": "Open menu",
    "选择用Safari打开下载": "Select 'Open in Safari' to download",
    "点击下方复制网址按钮，粘贴至Safari打开下载": "Click the button below to copy the URL and paste it into Safari to download",
    "复制网址打开Safari浏览器打开下载": "Copy the URL and open it in Safari browser to download",
    "请注意": "Please note",
    "您即将访问的应用为用户自行上传，请甄别应用风险后进行下载": "The app you are about to visit is uploaded by users themselves. Please identify the risks of the app before downloading.",
    "继续访问": "Continue visiting",
    "请输入": "Please enter",
    "密码": "Password",
    "授权码": "Authorization code",
    "立即进入": "Enter now",
    "版本": "Version",
    "大小": "Size",
    "更新时间": "Update time",
    "授权安装使用教程": "Authorized installation tutorial",
    "未受信任的企业级开发者的解决办法": "Solution for untrusted enterprise developers",
    "安装配置": "Installation configuration",
    "下载安装": "Download and install",
    "正在安装，请按home键返回桌面查看": "Installing, please press the home button to return to the desktop to view",
    "信任开发": "Trust Developer",
    "该应用来源于第三方产品, 请甄别风险后进行下载": "This app is from a third-party product, please identify the risks before downloading",
    "我已知晓内测应用的来源，并同意自行承担下载此APP所带来的一切风险": "I am aware of the source of the beta app and agree to bear all risks of downloading this app on my own",
    "扫描二维码下载": "Scan the QR code to download",
    "应用描述": "App description",
    "应用介绍": "App introduction",
    "联系方式": "Contact information",
    "备注": "Remarks",
    "免责声明": "Disclaimer",
    "举报反馈": "Report feedback",
    "APP备案号": "APP filing number",
    "应用仅供内部测试，请谨慎辨别下载": "The application is for internal testing only, please discern carefully before downloading",
    "返回": "Back",
    "该应用为“咕噜分发”实名用户自行上传至咕噜分发使用CDN加速下载，未绑定自主域名内测": "This app is uploaded by real-name users of GuLu distribution for use of CDN acceleration download without binding to their own domain name for beta testing",
    "如发现违规应用，请你在跳转后点击举报": "If you find any violation, please report it after redirection",
    "确定": "Confirm",
    "1.咕噜分发平台对该等程序仅可进行初步的形式审查，无法详细分析每一应用程序的运行后果及风险。请您审慎地甄别应用程序的质量，切勿因为垃圾短信、邮件、文字说明等内容随意安装来源不可靠的应用程序，请根据应用安装链接的来源自行判断并安装可信任的应用程序。对于通过咕噜分发平台发布的应用程序或咕噜分发平台上展示的其他站点的链接，咕噜分发平台不对该程序或站点的规则、政策或具体内容负责。": "1. GuLu Distribution Platform can only conduct preliminary formal review of such programs, and cannot analyze in detail the consequences and risks of each application. Please carefully identify the quality of the application, do not install unreliable applications casually due to junk messages, emails, text descriptions, etc. Please judge and install trustworthy applications according to the source of the application installation link. For applications published through GuLu Distribution Platform or links to other sites displayed on GuLu Distribution Platform, GuLu Distribution Platform is not responsible for the rules, policies, or specific content of the program or site.",
    "2.咕噜分发内测服务平台为第三方应用提供应用测试托管、应用分布式CDN加速下载服务。应用内容均来源于第三方产品，咕噜分发平台仅为用户提供内测支持，不涉及任何人工编辑和整理，也不参与任何应用的内容开发和对外运营，不对任何来源于第三方的内容（包括但不限于安装包安全、信息描述、应用截图）承担责任，您可根据描述场景谨慎选择进行内测试用。": "2. GuLu Distribution beta service platform provides application testing hosting and application distributed CDN acceleration download services for third-party applications. The application content comes from third-party products, and GuLu Distribution Platform only provides beta support to users, without any manual editing and sorting, and does not participate in the content development and external operation of any application. GuLu Distribution Platform is not responsible for any content originating from third parties (including but not limited to installation package security, information description, application screenshots). You can carefully choose to test internally according to the described scenario.",
    "3.在您通过咕噜分发平台安装或使用应用程序的过程中，咕噜分发平台不会要求您提供任何个人信息，但我们无法控制亦无法保证应用程序开发者不会要求您提供个人信息。无论依据国家法律、法规的规定，应用程序开发者收集、使用您的个人信息应当遵循合法、正当、必要的原则，公开收集规则，向您明示收集、使用信息的目的、方式和范围，并且必须经过您的同意。应用程序开发者不得收集与其提供服务无关的个人信息，不得违反法律、法规规定或与您之间的约定收集、使用您的个人信息。如您在通过咕噜分发平台安装或使用应用程序的过程中被要求提供个人信息，请您审慎考虑并自行决定是否提供。": "3. During the process of installing or using the application through GuLu Distribution Platform, GuLu Distribution Platform will not require you to provide any personal information, but we cannot control or guarantee that application developers will not require you to provide personal information. Regardless of the provisions of national laws and regulations, the collection and use of your personal information by application developers shall follow the principles of legality, legitimacy, and necessity, openly disclose collection rules, and clarify to you the purpose, method, and scope of collection and use of information, and must obtain your consent. Application developers shall not collect personal information unrelated to the services they provide, nor shall they collect or use your personal information in violation of laws, regulations, or agreements with you. If you are required to provide personal information during the process of installing or using the application through GuLu Distribution Platform, please consider carefully and decide whether to provide it on your own.",
    "4.任何公司、产品或者个人认为咕噜分发平台涉嫌侵犯您的版权或应用权，您应该及时向我们提供举报声明、书面权利通知，并提供身份证明、权属证明及侵权情况等投诉材料。我们将依法进行处理。": "4. Any company, product, or individual who believes that GuLu Distribution Platform infringes your copyright or application rights, you should promptly provide us with a statement of complaint, written notice of rights, and provide identity proof, ownership proof, and infringement materials, etc. We will handle it according to law.",
    "5.咕噜分发平台的服务是按照现有技术和条件所能达到的现状提供的。咕噜分发平台会尽最大努力向您提供服务，确保服务的连贯性和安全性；但咕噜分发平台不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。即使咕噜分发平台提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为咕噜分发平台违约，同时，由此给您造成的数据或信息丢失等损失的，您同意放弃追究咕噜分发平台的责任。": "5. GuLu Distribution Platform's services are provided according to the current technical and conditions. GuLu Distribution Platform will make every effort to provide you with services, ensure the continuity and security of services; however, GuLu Distribution Platform cannot guarantee that the services provided are flawless, nor can it foresee and prevent legal, technical, and other risks at any time, including but not limited to force majeure, viruses, Trojans, hacker attacks, system instability, defects in third-party services, government actions, etc., which may cause interruptions in service, data loss, and other losses and risks. Even if there are flaws in the services provided by GuLu Distribution Platform, if the above-mentioned flaws are unavoidable at the time due to the industry's technical level, they will not be considered as a breach of contract by GuLu Distribution Platform. At the same time, you agree to waive any liability of GuLu Distribution Platform for losses such as data or information loss caused thereby.",
    "6.咕噜分发平台平台所接触的内容和信息来源广泛，我方无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。咕噜分发平台不对用户在我方平台上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。我方平台在法律有明确规定的情况下承担相应的责任。": "6. GuLu Distribution Platform has extensive access to content and information sources, and we cannot be responsible for the accuracy, truthfulness, availability, security, integrity, and legitimacy of this content and information. GuLu Distribution Platform does not endorse, recommend, or express any views on any content and information uploaded, published, or transmitted by users on our platform, nor does it assume any responsibility for any errors, defects, losses, or damages caused by any content and information. You assume all risks related to the use of content and information. Our platform assumes corresponding responsibilities in cases where the law has clear regulations.",
    "作为第三方内测分发平台，我们一直致力于打击违规应用，保护用户权益。非常感谢您的举报，我们会在第一时间安排专人处理您的举报问题。感谢您对我们的支持。": "As a third-party beta distribution platform, we have been committed to combating illegal applications and protecting user rights. Thank you very much for your report. We will arrange for someone to handle your report as soon as possible. Thank you for your support.",
    "请选择举报类型": "Please select the type of report",
    "举报应用下载地址": "Report application download address",
    "请输入举报应用下载地址": "Please enter the reported application download address",
    "举报理由": "Report reason",
    "请选择举报理由": "Please select the report reason",
    "是否造成金钱损失": "Did it cause financial loss?",
    "请选择是或否": "Please select yes or no",
    "是": "Yes",
    "否": "No",
    "请选择是否造成金钱损失": "Please select whether it caused financial loss",
    "举报原因": "Report reason",
    "请填写举报原因": "Please fill in the report reason",
    "你的手机号码": "Your phone number",
    "请填写手机号": "Please fill in your phone number",
    "请输入短信验证码": "Please enter the SMS verification code",
    "获取验证码": "Get verification code",
    "返回下载页": "Return to download page",
    "举报": "Report",
    "我同意": "I agree",
    "向右滑动": "Swipe right",
    "应用不存在或已删除": "Application does not exist or has been deleted",
    "发送成功": "Sent successfully",
    "请求失败": "Request failed",
    "请求错误": "Request error",
    "提示": "Hint",
    "取消": "Cancel",
    "请勾选免责声明": "Please check the disclaimer",
    "请填验证码": "Please fill in the verification code",
    "提交成功": "Submission successful",
    "请使用手机扫描二维码下载": "Please use your phone to scan the QR code to download",
    "咕噜分发的所有应用都来源于第三方产品，内容不受咕噜分发控制，请您仔细甄别，审慎安装，咕噜分发仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保活承诺，咕噜分发不为因此产生的任何投资、购买行为承担任何责任": "All applications of GuLu distribution are from third-party products, and the content is not controlled by GuLu distribution. Please carefully discern and install. GuLu distribution is only an information service platform, and there is no affiliation with third-party applications",
    "关于咕噜": "About GuLu",
    "访问咕噜": "Visit GuLu",
    "未受信任的企业级开发者": "Untrusted enterprise developers",
    "盗版": "Piracy",
    "黄色": "Pornography",
    "欺诈": "Fraud",
    "其它": "Other",
    "补充举报原因": "Supplementary report reasons",
	"开发者信息": "Developer Information",
	"开发者姓名": "Developer Name",
	"开发者手机号": "Developer Phone Number",
	"开发者邮箱": "Developer Email",
	"请同意风险声明": "Please agree to the risk statement"
	
}