import axios from "axios";
import getSecretStr from "@/utils/secretStr.js";

//正式api域名
// export const apiDomain = "https://web.gulufenfa.com"
//测试api域名
export const apiDomain = "https://web.gulufenfa.com";
const isFormData = (v) => {
  return Object.prototype.toString.call(v) === '[object FormData]';
};
const axiosInstance = axios.create({
    baseURL: '/api', // 设置后端API的基础URL
    timeout: 60000, // 设置请求超时时间
  });

export const request = (url, params, option) => {
  if (!params) params = {}
  const ver = '2.0',
      mt = +new Date();
  if (isFormData(params)) {
    const compForm = {};
    for (var [key, value] of params) {
      if (typeof value === 'string' || typeof value === 'number') {
        compForm[key] = value;
      }
    }
    params.append(
      'sign',
      getSecretStr({
        ...compForm,
        ver,
        mt,
      }),
    );
  } else {
    params.sign = getSecretStr({
      ...params,
      ver,
      mt,
    });
  }
	return new Promise((resolve, reject) => {
		axios.post(apiDomain + url + `?ver=${ver}&mt=${mt}`, params, option).then((res) => {
			if (res.status != 200 || res.data?.code != 0)
				reject(res.data?.msg || "请求失败")
            else {
                resolve(res.data.data)
            }
		}).catch(err => reject(err))
	});
};

export const request2 = (url, params, option) => {
  if (!params) params = {}
  const ver = '2.0',
      mt = +new Date();
  if (isFormData(params)) {
    const compForm = {};
    for (var [key, value] of params) {
      if (typeof value === 'string' || typeof value === 'number') {
        compForm[key] = value;
      }
    }
    params.append(
      'sign',
      getSecretStr({
        ...compForm,
        ver,
        mt,
      }),
    );
  } else {
    params.sign = getSecretStr({
      ...params,
      ver,
      mt,
    });
  }
	return new Promise((resolve, reject) => {
		axios.post(url + `?ver=${ver}&mt=${mt}`, params, option).then((res) => {
      resolve(res)
		}).catch(err => reject(err))
	});
};
