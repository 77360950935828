export default {
    // "请点击右上角":"",
    // "打开菜单":"",
    // "选择用Safari打开下载":"",
    // "点击下方复制网址按钮，粘贴至Safari打开下载":"",
    // "复制网址打开Safari浏览器打开下载":"",
    // "请注意":"",
    // "您即将访问的应用为用户自行上传，请甄别应用风险后进行下载":"",
    // "继续访问":"",
    // "请输入":"",
    // "密码":"",
    // "授权码":"",
    // "立即进入":"",
    // "版本":"",
    // "大小":"",
    // "更新时间":"",
    // "授权安装使用教程":"",
    // "未受信任的企业级开发者的解决办法":"",
    // "安装配置":"",
    // "下载安装":"",
    // "正在安装，请按home键返回桌面查看":"",
    // "信任开发":"",
    // "该应用来源于第三方产品, 请甄别风险后进行下载":"",
    // "我已知晓内测应用的来源，并同意自行承担下载此APP所带来的一切风险":"",
    // "扫描二维码下载":"",
    // "应用描述":"",
    // "应用介绍":"",
    // "联系方式":"",
    // "备注":"",
    // "免责声明":"",
    // "举报反馈":"",
    // "APP备案号":"",
    // "应用仅供内部测试，请谨慎辨别下载":"",
    // "返回":"",
    // "该应用为“咕噜分发”实名用户自行上传至咕噜分发使用CDN加速下载，未绑定自主域名内测":"",
    // "如发现违规应用，请你在跳转后点击举报":"",
    // "确定":"",
    // "1.咕噜分发平台对该等程序仅可进行初步的形式审查，无法详细分析每一应用程序的运行后果及风险。请您审慎地甄别应用程序的质量，切勿因为垃圾短信、邮件、文字说明等内容随意安装来源不可靠的应用程序，请根据应用安装链接的来源自行判断并安装可信任的应用程序。对于通过咕噜分发平台发布的应用程序或咕噜分发平台上展示的其他站点的链接，咕噜分发平台不对该程序或站点的规则、政策或具体内容负责。":"",
    // "2.咕噜分发内测服务平台为第三方应用提供应用测试托管、应用分布式CDN加速下载服务。应用内容均来源于第三方产品，咕噜分发平台仅为用户提供内测支持，不涉及任何人工编辑和整理，也不参与任何应用的内容开发和对外运营，不对任何来源于第三方的内容（包括但不限于安装包安全、信息描述、应用截图）承担责任，您可根据描述场景谨慎选择进行内测试用。":"",
    // "3.在您通过咕噜分发平台安装或使用应用程序的过程中，咕噜分发平台不会要求您提供任何个人信息，但我们无法控制亦无法保证应用程序开发者不会要求您提供个人信息。无论依据国家法律、法规的规定，应用程序开发者收集、使用您的个人信息应当遵循合法、正当、必要的原则，公开收集规则，向您明示收集、使用信息的目的、方式和范围，并且必须经过您的同意。应用程序开发者不得收集与其提供服务无关的个人信息，不得违反法律、法规规定或与您之间的约定收集、使用您的个人信息。如您在通过咕噜分发平台安装或使用应用程序的过程中被要求提供个人信息，请您审慎考虑并自行决定是否提供。":"",
    // "4.任何公司、产品或者个人认为咕噜分发平台涉嫌侵犯您的版权或应用权，您应该及时向我们提供举报声明、书面权利通知，并提供身份证明、权属证明及侵权情况等投诉材料。我们将依法进行处理。":"",
    // "5.咕噜分发平台的服务是按照现有技术和条件所能达到的现状提供的。咕噜分发平台会尽最大努力向您提供服务，确保服务的连贯性和安全性；但咕噜分发平台不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。即使咕噜分发平台提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为咕噜分发平台违约，同时，由此给您造成的数据或信息丢失等损失的，您同意放弃追究咕噜分发平台的责任。":"",
    // "6.咕噜分发平台平台所接触的内容和信息来源广泛，我方无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。咕噜分发平台不对用户在我方平台上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。我方平台在法律有明确规定的情况下承担相应的责任。":"",
    // "作为第三方内测分发平台，我们一直致力于打击违规应用，保护用户权益。非常感谢您的举报，我们会在第一时间安排专人处理您的举报问题。感谢您对我们的支持。":"",
    // "请选择举报类型":"",
    // "举报应用下载地址":"",
    // "请输入举报应用下载地址":"",
    // "举报理由":"",
    // "请选择举报理由":"",
    // "是否造成金钱损失":"",
    // "请选择是或否":"",
    // "是":"",
    // "否":"",
    // "请选择是否造成金钱损失":"",
    // "举报原因":"",
    // "请填写举报原因":"",
    // "你的手机号码":"",
    // "请填写手机号":"",
    // "请输入短信验证码":"",
    // "获取验证码":"",
    // "返回下载页":"",
    // "举报":"",
    // "我同意":"",
    // "向右滑动":"",
    // "应用不存在或已删除":"",
    // "发送成功":"",
    // "请求失败":"",
    // "请求错误":"",
    // "提示":"",
    // "取消":"",
    // "请勾选免责声明":"",
    // "请填验证码":"",
    // "提交成功":"",
    // "请使用手机扫描二维码下载":"",
    // "咕噜分发的所有应用都来源于第三方产品，内容不受咕噜分发控制，请您仔细甄别，审慎安装，咕噜分发仅为信息服务平台，与第三方应用之间不存在任何关联关系，不提供任何担保活承诺，咕噜分发不为因此产生的任何投资、购买行为承担任何责任":"",
    // "关于咕噜":"",
    // "访问咕噜":"",
    // "未受信任的企业级开发者":"",
    // "盗版":"",
    // "黄色":"",
    // "欺诈":"",
    // "其它":"",
    // "补充举报原因":""

}